<template>
  <div>
    <div class="topButton">
      <CRow>
        <CCol sm="4">
          <div class="titleFont floatLeft">用戶管理</div>
        </CCol>
        <CCol sm="8">
          <CButton
            variant="outline"
            class=" floatRight rbutton"
            color="primary"
            @click="createCompany()"
          >
            建立用戶
          </CButton>

          <!-- @input="getSearch" -->
        </CCol>
      </CRow>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <div class="positionR">
          <div class="filterSelect">
            <vue-typeahead-bootstrap
              label="帳號"
              :data="getSearchData"
              v-model="search"
              placeholder="搜尋帳號"
            />
          </div>
        </div>
        <CDataTable
          :items="users"
          :fields="fields"
          :sorter="{ external: true }"
          @update:sorter-value="handlerSorterValueChange"
          hover
          :noItemsView="{ noItems: '目前無用戶資料' }"
        >
          <template #edit="{item, index}">
            <td class="py-2">
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="view(item, index)"
              >
                檢視
              </CButton>
              <CButton
                color="dark"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="edit(item, index)"
              >
                編輯
              </CButton>
            </td>
          </template>
        </CDataTable>

        <div v-if="page != undefined">
          <CPagination
            :activePage.sync="page.current_page"
            :pages="page.last_page"
            align="end"
            @click.native="get(page.current_page)"
            v-if="page != ''"
          />
        </div>
      </CCardBody>
    </CCard>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
export default {
  name: 'Clients',
  //   props: ['itemTable', 'fieldsTable'],
  data() {
    return {
      details: [],
      collapseDuration: 0,
      userAuth: sessionStorage.getItem('userAuth'),
      search: '',
      orderBy: '',
      sortedBy: '',
      selected: '請選擇',
      users: [
        { id: 1, account: 'ECV-User-0001', company: 'ECV', role: 'Head' },
        { id: 2, account: 'ECV-User-0002', company: 'ECV', role: 'Normal' },
        { id: 3, account: 'CA-User-0001', company: 'CompanyA', role: 'Head' },
        { id: 4, account: 'CA-User-0002', company: 'CompanyA', role: 'Normal' },
      ],
      page: { current_page: 1, last_page: 1 },
      usersearch: '',
      getSearchData: [],
      getSearchID: [],
    }
  },
  components: {
    Modal,
    VueTypeaheadBootstrap,
  },
  computed: {
    ...mapGetters([
      'get_companies',
      'get_companiesPage',
      'get_inStock',
      'get_companiesAll',
    ]),
    fields() {
      return [
        { key: 'account', label: '帳號' },
        { key: 'company', label: '所屬公司' },
        { key: 'role', label: '角色' },

        {
          key: 'edit',
          label: '',
          _style: 'width:20%',
          sorter: false,
          filter: false,
        },
      ]
    },
    // 公司下拉
    selectOptions() {
      if (this.company) {
        return this.company.map((g) => ({
          label: g.name,
          id: g.id,
        }))
      }
    },
  },
  watch: {
    selectOptions() {
      $('.vs__search').attr('value', '請選擇')
    },
  },
  methods: {
    handlerSorterValueChange(o) {
      this.orderBy = o.column
      this.sortedBy = o.asc == true ? 'ASC' : 'DESC'
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    createCompany() {
      this.$router.push('/user/create')
    },
    view(item) {
      this.$router.push('/user/view/' + item.id)
    },
    edit(item) {
      this.$router.push('/user/edit/' + item.id)
    },
    // users(item) {
    //   this.$router.push('/provider/client_user/' + item.id)
    // },
    get(page) {
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: page,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    Contract(item) {
      this.$router.push('/provider/company/contract/' + item.id)
    },
    getCompany() {
      let data = {
        companyName: this.search,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
  },
  created() {
    // this.orderBy = 'created_at'
    // this.sortedBy = 'DESC'
    // let data = {
    //   orderBy: this.orderBy,
    //   sortedBy: this.sortedBy,
    // }
    // this.$store.dispatch('actionCompanies', data)
    // let dataCompany = {
    //   orderBy: '',
    //   sortedBy: 'DESC',
    // }
    // this.$store.dispatch('actionCompaniesAll', dataCompany)
  },
}
</script>
